import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"

import portrait from "../images/portrait_grey_full.jpg"

import cc from "../images/programs/cc.svg"
import corel from "../images/programs/corel.svg"

import figma from "../images/programs/figma.svg"
import miro from "../images/programs/miro.svg"

import procreate from "../images/programs/procreate.png"
import illustrator from "../images/programs/illustrator.svg"

const SecondPage = () => (
  <Layout>
    <Seo title="About me" />
    <div className="darker-grey">
      <div className="content" style={{ paddingBottom: 0 }}>
        <div className="grid grid--2" style={{ gridGap: 0 }}>
          <div className="col-1 about-text">
            <h2>About me</h2>
            <p>
              I use my skills in typography, color theory, and layout to design
              digital products that are intuitive and visually appealing. My
              focus is on creating user-friendly experiences that balance
              functionality and design.
            </p>
          </div>
          <img
            className="col-1"
            src={portrait}
            alt="Portrait"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
    <div className="content space">
      <h3>My skillset</h3>
      <div className="grid break-early skills">
        <div className="col-1">
          <h4>Interaction Design</h4>
          <p>
            Designing user interfaces from lo-fi prototypes all the way to
            pixel-perfect screens in close collaboration with product managers
            and the development team.
          </p>
          <img src={figma} title="Figma" alt="Figma logo" />
          <img src={miro} title="Miro" alt="Miro logo" />
        </div>
        <div className="col-1">
          <h4>Design</h4>
          <p>
            Graphic Design, Animation, Websites, Applications or other design
            related tasks. I love tackling analog and digital problems and help
            fix them.
          </p>
          <img src={cc} alt="Adobe CC logo" title="Adobe Creative Cloud" />
          <img src={corel} alt="Corel Draw logo" title="Corel Draw" />
        </div>
        <div className="col-1">
          <h4>Illustration</h4>
          <p>
            While illustrating I truly forget time passing. It's the thing that
            keeps me up at night. Illustration is where I feel truly at home
            weather it's just simple pen and paper or using Procreate on the
            iPad.
          </p>
          <img src={procreate} title="Procreate" alt="Procreate logo" />
          <img src={illustrator} title="Illustrator" alt="Illustrator logo" />
        </div>
      </div>
    </div>

    <div className="content space cv">
      <div className="grid grid--2">
        <div className="col-1">
          <h3>Experience</h3>

          <div>
            <p>since October 2023</p>
            <h4>Interaction Designer</h4>
            <p>
              Interface design for Swisscom{" "}
              <a target="_blank" rel="noreferrer" href="https://tv.blue.ch/">
                Blue TV
              </a>
              <br />
              Swisscom, Zurich (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.swisscom.ch"
              >
                swisscom.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>August 2022 - September 2023</p>
            <h4>UX Designer</h4>
            <p>
              UI/UX design for various projects in the energy sector
              <br />
              BKW, Bern (
              <a target="_blank" rel="noreferrer" href="https://bkw.ch">
                bkw.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>April 2021 - August 2022</p>
            <h4>UX Designer</h4>
            <p>
              UI/UX design for a web application for marketing teams, refinement
              of design system based on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://m3.material.io/"
              >
                material.io
              </a>
              <br />
              uhub.io, Bern (
              <a target="_blank" rel="noreferrer" href="https://www.uhub.io">
                uhub.io
              </a>
              )
            </p>
          </div>

          <div>
            <p>May 2020 – Oct 2021</p>
            <h4>Freelance Designer</h4>
            <p>
              Branding, digital and analog projects (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://jennifer.zosel.ch"
              >
                jennifer.zosel.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>2015 – 2020</p>
            <h4>Graphic Designer</h4>
            <p>
              Signage, photomontage and marketing
              <br />
              Westiform AG, Niederwangen b. Bern (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.westiform.com"
              >
                westiform.com
              </a>
              )
            </p>
          </div>

          <div>
            <p>2014 – 2015</p>
            <h4>Internship Graphic&thinsp;/&thinsp;Webdesign</h4>
            <p>
              Artworking, Beatenberg (
              <a target="_blank" rel="noreferrer" href="https://artworking.ch">
                artworking.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>2008 – 2009</p>
            <h4>Exchange Student&thinsp;/&thinsp;Language studies</h4>
            <p>Osaka, Japan</p>
          </div>
        </div>
        <div className="col-1">
          <h3>Education</h3>
          <div>
            <p>2019 – 2022</p>
            <h4>HF Interaction Design</h4>
            <p>
              School for Design Bern&thinsp;/&thinsp;Biel (
              <a target="_blank" rel="noreferrer" href="https://sfgb-b.ch">
                sfgb-b.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>2018 – 2019</p>
            <h4>Pre-Press Technology Specialist (BP)</h4>
            <p>
              School for Design, Zurich (
              <a target="_blank" rel="noreferrer" href="https://sfgz.ch">
                sfgz.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>2010 – 2014</p>
            <h4>Printing Technologist&thinsp;/&thinsp;Screen Printing (EFZ)</h4>
            <p>
              School for Design, Zurich (
              <a target="_blank" rel="noreferrer" href="https://sfgz.ch">
                sfgz.ch
              </a>
              )
            </p>
          </div>

          <div>
            <p>2007 – 2008</p>
            <h4>Vorkurs für Kunst und Gestaltung</h4>
            <p>
              School for Design Bern&thinsp;/&thinsp;Biel (
              <a target="_blank" rel="noreferrer" href="https://sfgb-b.ch">
                sfgb-b.ch
              </a>
              )
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="light-grey">
      <Contact />
    </div>
  </Layout>
)

export default SecondPage
