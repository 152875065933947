import React from "react"

import linkedin from "../images/icons/linkedin.svg"

const Contact = () => (
  <div className="content contact space">
    <h3>Contact</h3>
    <p>
      If you have some feedback, a question, or just want to say hi - please get
      in touch.
    </p>
    <div className="flex-between">
      <div className="smallest-space">
        <a href="mailto:jennifer@zosel.ch">jennifer@zosel.ch</a>
      </div>
      <div className="social-icons">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/jennifer-zosel-b32bb5188/"
        >
          <img
            className="hover"
            src={linkedin}
            title="LinkedIn"
            alt="Find me on LinkedIn"
          />
        </a>
      </div>
    </div>
    <hr className="separator" />
    <p className="small">© Jennifer Zosel</p>
  </div>
)

export default Contact
